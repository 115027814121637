@media(max-width: 575.98px)
{
    .rounded-pill
    {
        border-radius: 0rem!important;
    }
}

.video-player {
    width: 100%;
    height: 100%;
}

.projectinfo {
    background-color: #141414;
    /* height: 100vh; */
}

.projectinfo h1,h2,h3 {
    text-align: center;
}

.projectImage {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.imageList {
    height: 50vh;
}

.imageList span {
    filter: none!important;
}

.carousel-indicators button {
    background-color: white!important;
}

.imageList>.carousel-indicators {
    bottom: -50px;
    filter: none;
}

.imageList>*[class^='carousel-control'], .imageList>.carousel-inner {
    height: 100%;
}