.contact-background
{
    background-color: #0f1414;
    padding: 24px 8px;
}

.contact-form
{
    max-width: 800px;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    background-color: #111616;
    padding: 24px;
    gap: 12px;
}