html {
    scroll-behavior: smooth;
}

.headerContainer {
    /* margin-top: 20rem; */
    display: flex;
    /*height: 40rem;*/
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.stickyHeader {
    width: 100% !important;
    left: 0px;
    background-color: #141414;
    z-index: 50;
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.stickyHeader>.list-inline {
  margin: 0px;
  display: flex;
  justify-content: end;
}

.stickyHeader>.subtext {
  display: none;
}

#scroll a {
    padding-top: 80px;
  }
  #scroll a span {
    position: absolute;
    bottom: 48px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb07 2s infinite;
    animation: sdb07 2s infinite;
    opacity: 0;
    box-sizing: border-box;
  }
  #scroll a span:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  #scroll a span:nth-of-type(2) {
    bottom: 32px;
    -webkit-animation-delay: .15s;
    animation-delay: .15s;
  }
  #scroll a span:nth-of-type(3) {
    bottom: 16px;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
  }
  @-webkit-keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }