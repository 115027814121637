.lang {
    top:1vh;
    z-index: 51;
}

.flag {
    min-height: 15px;
    max-height: 3vh;
    height:100%;
}

.lang-btn {
    width: 0%;
}