.projects {
    background-color: #0f1414; /*#2C3030;*/
    /* height: 40rem; */
}

.project {
     max-height: 30rem;
     /*max-width: 900px;*/
     height: 600px !important;
}

.carousel-item {
    height: 100%;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, .4);
    padding: 24px 24px;
    color: white!important;
    z-index: 10;
}

.projectCoverFront {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    z-index: 1;
}

.projectBackground {
    filter: blur(5px);
    height: 100%;
    object-fit: cover;
}